import React from 'react';
import { Link} from "gatsby";
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const Markets = () => {

  return (
    <section>
      <BS.Card style={{ marginTop: 0 }}>
        <BS.Card.Header>Healthtech Market</BS.Card.Header>
        <BS.Card.Body>
          <BS.Card.Text>
            Deep dive into the growing healthtech market.
    </BS.Card.Text>
          <Link to={`/market/healthtech/`}><BS.Button style={{ background: 'rgb(238 243 249)', border: 'rgb(33 98 175)', color: 'black' }}>Get the report</BS.Button></Link>
        </BS.Card.Body>
      </BS.Card>
     
      <BS.Card style={{ marginTop: 20 }}>
        <BS.Card.Header>Fintech Market</BS.Card.Header>
        <BS.Card.Body>
     
          <BS.Card.Text>
            Deep dive into the growing fintech market.
</BS.Card.Text>
          <Link to={`/market/fintech/`}><BS.Button style={{ background: 'rgb(238 243 249)', border: 'rgb(33 98 175)', color: 'black' }}>Get the report</BS.Button></Link>
        </BS.Card.Body>
      </BS.Card>
    </section>
  )
}
// allCategory(sort: {fields: howMany, order: DESC}) {

export default Markets






